/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero {
  width: 100%;
  min-height: 100vh;
  position: relative;
  padding: 80px 0 60px 0;
  display: flex;
  align-items: center;
  background: url(../../assets/hero-bg.png) top center no-repeat;
  background-size: cover;
}

.hero h1 {
  margin: 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
}

.hero p {
  color: color-mix(in srgb, var(--default-color), transparent 30%);
  margin: 5px 0 30px 0;
  font-size: 20px;
  font-weight: 400;
}

.hero .btn-get-started {
  color: var(--contrast-color);
  background: var(--accent-color);
  font-family: var(--heading-font);
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 40px;
  border-radius: 4px;
  transition: 0.5s;
  box-shadow: 0 8px 28px rgba(0, 0, 0, 0.1);
}

.hero .btn-get-started i {
  margin-left: 5px;
  font-size: 18px;
  transition: 0.3s;
}

.hero .btn-get-started:hover {
  color: var(--contrast-color);
  background: color-mix(in srgb, var(--accent-color), transparent 15%);
  box-shadow: 0 8px 28px rgba(0, 0, 0, 0.1);
}

.hero .btn-get-started:hover i {
  transform: translateX(5px);
}

.hero .btn-watch-video {
  font-size: 16px;
  transition: 0.5s;
  color: var(--default-color);
  font-weight: 600;
}

.hero .btn-watch-video i {
  color: var(--accent-color);
  font-size: 32px;
  transition: 0.3s;
  line-height: 0;
  margin-right: 8px;
}

.hero .btn-watch-video:hover {
  color: var(--accent-color);
}

.hero .btn-watch-video:hover i {
  color: color-mix(in srgb, var(--accent-color), transparent 15%);
}

.hero .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@media (max-width: 640px) {
  .hero h1 {
      font-size: 28px;
      line-height: 36px;
  }

  .hero p {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 30px;
  }
}

@keyframes up-down {
  0% {
      transform: translateY(10px);
  }

  100% {
      transform: translateY(-10px);
  }
}

/*--------------------------------------------------------------
# Dark Mode Styles
--------------------------------------------------------------*/
body.dark {
  background-color: #121212;
  color: white;
}

body.dark .hero {
  background: none; /* Remove background image for dark mode */
  background-color: #1a1a1a; /* Add dark background color */
}

body.dark .hero h1 {
  color: #ffffff; /* White text */
}

body.dark .hero p {
  color: rgba(255, 255, 255, 0.7); /* Lighter color for paragraph */
}

body.dark .hero .btn-get-started {
  background-color: #333;
  color: white;
  box-shadow: 0 8px 28px rgba(255, 255, 255, 0.1); /* Adjust shadow for dark mode */
}

body.dark .hero .btn-get-started:hover {
  background-color: #444; /* Lighter background on hover */
}

body.dark .hero .btn-watch-video {
  color: #ffffff;
}

body.dark .hero .btn-watch-video i {
  color: #ffffff;
}

body.dark .hero .btn-watch-video:hover {
  color: #999999;
}

body.dark .hero .btn-watch-video:hover i {
  color: #cccccc;
}

/* Dark mode button styles */
button {
  background-color: #007fff;
  color: white;
  padding: 10px;
  border: none;
}

button.dark-mode {
  background-color: #333;
  color: white;
}

/* Additional elements if necessary */
body.dark .custom-image {
  filter: brightness(0.8); /* Darken images slightly */
}

body.dark .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}




.btn-get-started {
  background-color: #007FFF;
  color: #ffffff;
  font-weight: 500; /* Lighter font weight */
  border: none;
  padding: 8px 16px; /* Smaller padding */
  border-radius: 20px; /* Softer, pill-shaped edges */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth color and scale effect */
  font-size: 0.9rem; /* Adjust font size if necessary */
}

.btn-get-started:hover {
  background-color: #005bbb;
  transform: scale(1.05); /* Slightly enlarges on hover */
}
